<template>
  <div
    id="geoloc-page"
    class="d-flex flex-column justify-content-between h-100 page py-3 px-2"
  >
    <div>
      <div class="my-3">
        <font-awesome-layers class="fa-5x">
          <font-awesome-icon icon="circle" class="text-primary" />
          <font-awesome-icon
            icon="location-arrow"
            class="text-white"
            transform="shrink-6"
          />
        </font-awesome-layers>
      </div>
      <h2 class="title">Trouvez le meilleur autour de vous !</h2>
      <p class="mb-5">
        Activez la géolocalisation pour récupérer facilement les meilleurs Bon
        Plans ou le Menu Du Jour
      </p>
    </div>
    <div>
      <btn-with-icon
        :icon="['fas', 'chevron-right']"
        variant="primary"
        block
        :only-icon="false"
        @click="geoloc()"
      >
        Continuer
      </btn-with-icon>
      <small class="d-block text-center mt-4">
        <b-link :to="{ name: 'list' }" class="text-center d-block text-muted">
          Plus tard
        </b-link>
      </small>
    </div>
  </div>
</template>
<script>
import UPDATE_USER from "@/graphql/mutations/UpdateUser.gql";
export default {
  data() {
    return {
      loading: false,
    };
  },
  created() {
    if (!navigator.geolocation) {
      this.$router.push({ name: "city" });
    }
  },
  methods: {
    geoloc() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.$store.commit("coordinate", {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        this.error = {};
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: UPDATE_USER,
            client: "auth",
            variables: {
              location: {
                lng: position.coords.longitude,
                lat: position.coords.latitude,
              },
            },
          })
          .then(() => {
            this.loading = false;
            this.$router.push({ name: "city" });
            resolve();
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },
  },
};
</script>
